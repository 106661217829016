import { useTheme } from '@mui/material/styles';
import { Stack, Box } from '@mui/material';
import { HEADER, NAV } from 'config-global';
import { hideScrollbarX } from 'utils/cssStyles';
import { NavSectionMini } from 'components/nav-section';
import { navConfig } from './config-navigation';

export default function NavMini() {
  const theme_style = useTheme();
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
        backgroundColor: theme_style.palette.background.paper,
      }}
      style={{
        paddingTop: HEADER.H_DASHBOARD_DESKTOP,
      }}
    >
      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
          paddingTop: theme_style.spacing(4),
        }}
      >
        <NavSectionMini data={navConfig} />
      </Stack>
    </Box>
  );
}
