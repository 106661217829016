import 'simplebar/src/simplebar.css';
import 'utils/mapboxgl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-quill/dist/quill.snow.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { StyledChart } from 'components/chart';
import SnackbarProvider from 'components/snackbar';
import Router from 'routes';
import ThemeProvider from 'theme';
import ScrollToTop from 'components/scroll-to-top';
import { MotionLazyContainer } from 'components/animate';
import { AuthProvider } from 'auth/JwtContext';

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <BrowserRouter>
          <ScrollToTop />
          <MotionLazyContainer>
            <ThemeProvider>
              <SnackbarProvider>
                <StyledChart />
                <Router />
              </SnackbarProvider>
            </ThemeProvider>
          </MotionLazyContainer>
        </BrowserRouter>
      </HelmetProvider>
    </AuthProvider>
  );
}
