import React, { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'utils/axios';
import localStorageAvailable from 'utils/localStorageAvailable';
import { setSession } from 'auth/utils';
import { PATHS } from '../routes/paths';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  isAdminToggled: false,
  onChangeAdmin: () => {},
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      isAdminToggled: action.payload.isAdminToggled,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      isAdminToggled: false,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      isAdminToggled: false,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      isAdminToggled: false,
      user: null,
    };
  }

  return state;
};

export const AuthContext = createContext(null);

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const storageAvailable = localStorageAvailable();
    (async () => {
      try {
        const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';

        if (accessToken) {
          setSession(accessToken);
          const response = await axios.get('/api/v1/user/get/info');
          if (response.status === 200) {
            const user = response.data;
            dispatch({
              type: 'INITIAL',
              payload: {
                isAuthenticated: true,
                isAdminToggled: false,
                user,
              },
            });
          }
        } else {
          dispatch({
            type: 'INITIAL',
            payload: {
              isAuthenticated: false,
              isAdminToggled: false,
              user: null,
            },
          });
        }
      } catch (error) {
        console.error(error);
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            isAdminToggled: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  const onChangeAdmin = useCallback((value, user) => {
    dispatch({
      type: 'INITIAL',
      payload: {
        isAuthenticated: true,
        isAdminToggled: value,
        user,
      },
    });
  }, []);

  /**
   * @param {{access:String}} response.data
   */
  const login = useCallback(async (email, password, erpUser) => {
    const loginData = new FormData();
    loginData.append('email', email);
    loginData.append('password', password);
    const response = await axios.post('api/token', loginData);

    const accessToken = response.data.access;
    setSession(accessToken);
    const user_response = await axios.get('/api/v1/user/get/info', {});
    const user = user_response.data;
    user.is_erp_user = erpUser;
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  }, []);

  /**
   * @param {{access:String}} response.data
   */
  const loginERP = useCallback(
    async (email, password, registered, employeeId) => {
      const erpLoginData = new FormData();
      erpLoginData.append('email', email);
      erpLoginData.append('password', password);
      const response = await axios.post('api/auth/erp_login', erpLoginData);

      if (response.data) {
        if (registered) {
          axios.interceptors.response.use(
            (r) => r,
            (error) => {
              const form_data = new FormData();
              form_data.append('email', email);
              form_data.append('new_password', password);
              form_data.append('confirm_password', password);
              axios.post('/api/auth/forgot_password', form_data).then(() => {
                login(email, password, true);
              });
            }
          );
          login(email, password, true);
        } else {
          const intranetRegisterData = new FormData();
          intranetRegisterData.append('employee_id', employeeId);
          intranetRegisterData.append('password', password);
          axios.post('/api/auth/register/create_user', intranetRegisterData).then((r) => {
            if (r.status === 201) {
              login(email, password, true);
            }
          });
        }
        return true;
      }
      console.log('incorrect erp password!');
      return false;
    },
    [login]
  );

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;
    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      isAdminToggled: state.isAdminToggled,
      user: state.user,
      method: 'jwt',
      login,
      loginERP,
      register,
      logout,
      onChangeAdmin,
    }),
    [
      state.isAuthenticated,
      state.isAdminToggled,
      state.isInitialized,
      state.user,
      login,
      loginERP,
      logout,
      register,
      onChangeAdmin,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
