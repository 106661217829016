import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Grid } from '@mui/material';
import { PATHS } from 'routes/paths';
import { useAuthContext } from 'auth/useAuthContext';
import { CustomAvatar } from 'components/custom-avatar';
import { useSnackbar } from 'components/snackbar';
import MenuPopover from 'components/menu-popover';
import { IconButtonAnimate } from 'components/animate';

const OPTIONS = [
  {
    label: 'Профайл',
    linkTo: PATHS.profile,
  },
];

export default function AccountPopover() {
  const theme1 = useTheme();
  const navigate = useNavigate();
  const { user, logout } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => setOpenPopover(event.currentTarget);
  const handleClosePopover = () => setOpenPopover(null);

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATHS.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <Grid
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={handleOpenPopover}
      >
        <IconButtonAnimate
          sx={{
            p: 0,
            ...(openPopover && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <CustomAvatar src={user?.profile} alt={user?.first_name} name={user?.first_name} />
        </IconButtonAnimate>
        <Typography
          noWrap
          sx={{
            fontSize: 13,
            fontWeight: 'bold',
            marginLeft: theme1.spacing(1),
            color: theme1.palette.text.primary,
          }}
        >
          {user?.first_name} {user?.last_name}
        </Typography>
      </Grid>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'solid' }} />

        <Stack sx={{ padding: theme1.spacing(1, 1, 0, 1) }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'solid' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Системээс гарах
        </MenuItem>
      </MenuPopover>
    </>
  );
}
