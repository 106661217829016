import PropTypes from 'prop-types';
import React, { List, Stack, Button } from '@mui/material';
import Odoo from 'assets/icons/navbar/Odoo.svg';
import Tableau from 'assets/icons/navbar/Tableau.svg';
import Mak from 'assets/icons/navbar/Mak.svg';
import { useTheme } from '@mui/material/styles';
import { StyledSubheader } from './styles';
import NavList from './NavList';


NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx, ...other }) {
  const theme = useTheme();
  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && <StyledSubheader disableSticky>{group.subheader}</StyledSubheader>}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}

            <Button
              sx={{
                width: '100%',
                height: '40px',
                color: '#637381',
                background: 'white',
                fontWeight: 'normal',
                borderRadius: '7px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(1),
                '&:hover': {
                  background: '#f5f4f6', // Changer la couleur de fond au survol
                },
              }}
              href="https://tableau.mak.mn"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Tableau"
                src={Tableau}
                style={{ height: '80%', marginRight: theme.spacing(2), marginLeft: theme.spacing(-9) }}
              />
              Open in Tableau
            </Button>
            
            <Button
              sx={{
                width: '100%',
                height: '40px',
                color: '#637381',
                fontWeight: 'normal',
                background: 'white',
                borderRadius: '7px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(1),
                '&:hover': {
                  background: '#f5f4f6', // Changer la couleur de fond au survol
                },
              }}
              href="https://erp.mak.mn"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Odoo"
                src={Odoo}
                style={{ height: '80%', marginRight: theme.spacing(2), marginLeft: theme.spacing(-7) }}
              />
              Open in Odoo ERP
            </Button>

            <Button
              sx={{
                width: '100%',
                height: '40px',
                color: '#637381',
                fontWeight: 'normal',
                background: 'white',
                borderRadius: '7px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: theme.spacing(1),
                '&:hover': {
                  background: '#f5f4f6', // Changer la couleur de fond au survol
                },
              }}
              href="https://www.mak.mn/360-virtual-tour"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Mak"
                src={Mak}
                style={{ height: '80%', marginRight: theme.spacing(-0), marginLeft: theme.spacing(-15)}}
              />
              360 TOUR
            </Button>

          </List>
        );
      })}
    </Stack>
  );
}
