import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from 'auth/useAuthContext';
import { PATHS, AUTHORIZED_PATHS, UNAUTHORIZED_PATHS } from 'routes/paths';
import LoadingScreen from '../components/loading-screen';

export default function AuthGuard({ children }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, isInitialized } = useAuthContext();
  // console.log('pathname: ', pathname);
  // console.log('authenticated: ', isInitialized && isAuthenticated);

  useEffect(() => {
    if (!isInitialized) return;
    if (isAuthenticated) {
      if (
        pathname === PATHS.root ||
        UNAUTHORIZED_PATHS.includes(pathname) ||
        pathname.includes(PATHS.loginPassword)
      )
        navigate(PATHS.home);
    }
    if (!isAuthenticated) {
      if (pathname === PATHS.root || AUTHORIZED_PATHS.includes(pathname)) navigate(PATHS.login);
    }
  }, [isInitialized, isAuthenticated, navigate, pathname]);

  if (!isInitialized) return <LoadingScreen />;

  return <> {children} </>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
};
