import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Grid } from '@mui/material';
import { CustomAvatar } from 'components/custom-avatar';


function WeatherApp() {
  const theme1 = useTheme();
  const [userLocation, setUserLocation] = useState(null);
  const [weather, setWeather] = useState(null);
  const [city, setCity] = useState(null);

  const [currentLatitude, setLat] = useState([]);
  const [currentLongitude, setLong] = useState([]);
  const apiKey= 'dbeccb90255e0651e5c785df19faea50';
      

  useEffect(() => {
    const fetchData = async () => {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        
        setUserLocation({ latitude, longitude });
        setLong(position.coords.longitude);
        setLat(position.coords.latitude);
      });
      const url = 'https://api.openweathermap.org/data/2.5/weather';
      await axios
            .get(url, {
                params: {
                    lat: currentLatitude,
                    lon: currentLongitude,
                    units: 'metric',
                    appid: apiKey,
                },
            })
            .then((res) => {
             setWeather(res.data) 
            
      });
    }

    async function getCityName (lt, lg, api) {
        const response =  await fetch(`http://api.openweathermap.org/geo/1.0/reverse?lat=${lt}&lon=${lg}&appid=${api}`)
        const rdata  = await response.json();
        console.log("weather city response= ", rdata)
        setCity(rdata[0].name)
      }
    fetchData();
    getCityName(currentLatitude, currentLongitude, apiKey);
  }, [currentLatitude, currentLongitude])

  return (
    <div>
    
      {userLocation && !weather ? <p>Loading weather data...</p> : null}
      {weather ? (
        <Grid container
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight:10 }}
      > 
       <Grid item> 
        <CustomAvatar sx={{width:50}} src={`https://openweathermap.org/img/wn/${weather.weather[0].icon}@2x.png`} />
       </Grid>
        <Grid item>
            <Grid>
            <Typography
          noWrap
          sx={{
            fontSize: 13,
            fontWeight: 'normal',
            marginLeft: theme1.spacing(1),
            color: theme1.palette.text.primary,
          }}
        >
          {city}
        </Typography>
        <Typography
          noWrap
          sx={{
            fontSize: 18,
            fontWeight: 'bold',
            marginLeft: theme1.spacing(1),
            color: theme1.palette.text.primary,
          }}
        >
          {Math.round(weather.main.temp)} °C
        </Typography>

            </Grid>
            <Grid>
                <Typography noWrap
          sx={{
            fontSize: 10,
            fontWeight: 'normal',
            marginLeft: theme1.spacing(1),
            color: theme1.palette.text.primary,
          }}>
                {/* {weather.weather[0].description} */}
                </Typography>
            </Grid>
        </Grid>
        
      </Grid>
      ) : null}
      
    </div>
  );
}

export default WeatherApp;