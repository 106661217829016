import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  InputAdornment,
  Grid,
  Autocomplete,
  Typography,
  Link,
  ListItemButton,
} from '@mui/material';
import { bgBlur } from 'utils/cssStyles';
import axios from 'utils/axios';
import useOffSetTop from 'hooks/useOffSetTop';
import useResponsive from 'hooks/useResponsive';
import { HEADER, NAV } from 'config-global';
import Logo from 'components/logo';
import Iconify from 'components/iconify';
import { useSettingsContext } from 'components/settings';
import Image from 'components/image/Image';
import AccountPopover from './AccountPopover';
import WeatherApp from './Weather';
import CurrencyRate from './CurrencyRate';

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const { themeLayout } = useSettingsContext();
  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';
  const isDesktop = useResponsive('up', 'lg');
  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
  const [search, setSearch] = useState('');
  const [result_list, setResultList] = useState([]);

  useEffect(() => {
    axios.get(`/api/v1/post/?search=${search}&page=1`).then((response) => {
      if (response.status === 200) setResultList(response.data.results);
    });
  }, [search]);

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}
      <Grid
        sx={{
          width: isNavMini ? NAV.W_DASHBOARD_MINI : NAV.W_BASE,
          display: 'flex',
        }}
      >
        {!isDesktop && (
          <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}

        {!isNavMini && <Logo sx={{ mr: 5 }} />}
        {/* {isDesktop && ( */}
        {/*  <IconButton onClick={onToggleLayout} sx={{ mr: 1, color: 'text.primary' }}> */}
        {/*    <Iconify icon="eva:menu-2-fill" /> */}
        {/*  </IconButton> */}
        {/* )} */}
      </Grid>
      <Grid sx={{ display: 'contents' }}>
        {/**
         * @param {{publish_date_formatted:String}} option
         */}
        <Autocomplete
          fullWidth
          options={result_list}
          getOptionLabel={(option) => `${option.title} ${option.publish_date_formatted}`}
          renderOption={(props, option) => (
            <Link
              href={`/news?id=${option.id}`}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'none',
                },
                color: theme.palette.text.primary,
              }}
            >
              <ListItemButton sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid sx={{ padding: theme.spacing(0, 2, 0, 0) }}>
                  <Image src={option.image_thumbnail} sx={{ height: '40px', width: '60px' }} />
                </Grid>
                <Grid>
                  <Typography sx={{ fontSize: 14 }}>{option.title}</Typography>
                  <Typography
                    sx={{
                      ...theme.typography.caption,
                      marginRight: 'auto',
                      color: theme.palette.text.disabled,
                    }}
                  >
                    {option.publish_date_formatted}
                  </Typography>
                </Grid>
              </ListItemButton>
            </Link>
          )}
          sx={{ width: 'auto', display: 'contents' }}
          clearIcon={null}
          popupIcon={null}
          renderInput={(params) => (
            <InputBase
              fullWidth
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              {...params.InputProps}
              inputProps={{ ...params.inputProps, type: 'search' }}
              placeholder="Мэдээ хайx"
              value={search}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              }
              sx={{
                height: 'auto',
                minWidth: 200,
                padding: theme.spacing(0.5, 0.5, 0.5, 2),
                borderRadius: '8px',
                background: theme.palette.background.default,
                margin: theme.spacing(0, 2), // Ajustez la marge pour les différentes tailles d'écran
                [theme.breakpoints.up('sm')]: {
                  margin: theme.spacing(0, 3), // Marge pour les écrans de taille small et supérieure
                },
                [theme.breakpoints.up('md')]: {
                  margin: theme.spacing(0, 4), // Marge pour les écrans de taille medium et supérieure
                },
                [theme.breakpoints.up('lg')]: {
                  margin: theme.spacing(0, 5), // Marge pour les écrans de taille large et supérieure
                },
              }}
            />
          )}
        />
      </Grid>
      <CurrencyRate/>
      <WeatherApp/>
      <AccountPopover />
      
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.paper,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `100%!important`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.paper',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `100%!important`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
