import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Logo from 'components/logo';
import LoginBackground from 'assets/background/login_background.png';
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

export const LoginLayout = () => (
  <StyledRoot>
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        mt: { xs: 1.5, md: 5 },
        ml: { xs: 2, md: 5 },
      }}
    />

    <StyledContent sx={{ width: { xs: '100%', md: '50%' } }}>
      <Stack sx={{ width: 1 }}>
        <Outlet />
      </Stack>
    </StyledContent>

    <StyledSection
      sx={{
        background: `url(${LoginBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    >
      <StyledSectionBg />
    </StyledSection>
  </StyledRoot>
);
