import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import useResponsive from 'hooks/useResponsive';
import { useSettingsContext } from 'components/settings';
import { Main } from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';

export const MainLayout = () => {
  const { themeLayout } = useSettingsContext();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);
  const isNavHorizontal = themeLayout === 'horizontal';
  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const renderNavVertical = (
    <NavVertical
      openNav={open}
      onCloseNav={handleClose}
      style={{ width: '100%', position: 'absolute' }}
    />
  );

  if (isNavHorizontal) {
    return (
      <>
        <Header onOpenNav={handleOpen} sx={{ width: '100%', position: 'absolute' }} />
        {isDesktop ? <NavHorizontal /> : renderNavVertical}
        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
        <Header onOpenNav={handleOpen} sx={{ width: '100%', position: 'absolute' }} />

        <Box sx={{ display: { lg: 'flex' }, minHeight: { lg: 1 } }}>
          {isDesktop ? <NavMini /> : renderNavVertical}
          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box sx={{ display: { lg: 'flex' }, minHeight: { lg: 1 } }}>
        {renderNavVertical}
        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
};
